import React, { useState, useRef, useEffect } from 'react';
import '../styles/gyg.css';
import '../styles/logo.css';

const GYG = () => {
  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const emailInputRef = useRef(null);

  const CONFIG = {
    emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    API_URL: 'https://api.supplystore.store/verify'
  };

  const validateEmail = () => {
    const isValid = CONFIG.emailRegex.test(originalEmail || email.trim());
    if (!isValid) {
      setError('Please enter a valid e-mail address');
      return false;
    }
    setError('');
    return true;
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    if (!validateEmail()) return;

    setLoading(true);
    try {
      const response = await fetch(CONFIG.API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: originalEmail || email.trim() })
      });
      const data = await response.json();

      if (data.code) {
        if (!isVerified) {
          setOriginalEmail(email.trim());
        }
        setEmail(data.code);
        setIsVerified(true);
        emailInputRef.current.readOnly = true;
      } else if (data.error) {
        setError(data.error);
      }
    } catch (error) {
      setError('Network error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEmail('');
    setOriginalEmail('');
    setError('');
    setIsVerified(false);
    setShowCopyMessage(false);
    if (emailInputRef.current) {
      emailInputRef.current.readOnly = false;
      emailInputRef.current.placeholder = 'Email *';
      emailInputRef.current.type = 'email';
    }
  };

  const handleCopyCode = async () => {
    if (isVerified) {
      try {
        await navigator.clipboard.writeText(email);
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000);
      } catch (err) {
        console.error('Failed to copy text:', err);
      }
    }
  };

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.addEventListener('input', validateEmail);
    }
    return () => {
      if (emailInputRef.current) {
        emailInputRef.current.removeEventListener('input', validateEmail);
      }
    };
  }, [email, originalEmail]);

  return (
    <main className="main-content">
      <div className="logo-container">
        <div className="logo">
          <img
            src="gyg.png"
            alt="Logo"
            className="bouncing-logo"
          />
        </div>
      </div>



      <form className="form-container" id="emailForm" onSubmit={e => e.preventDefault()}>
        <div className="form-group">
          <div className="input-container">
            <input
              ref={emailInputRef}
              type="email"
              className={`input-field ${error ? 'error' : ''} ${isVerified ? 'verified' : ''}`}
              placeholder="Email *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              onClick={handleCopyCode}
              required
            />
            <button
              type="button"
              className="reset-button"
              onClick={resetForm}
            >
              RESET
            </button>
          </div>
          <div className="loading-spinner" />
          {error && <div className="error-message show">{error}</div>}
          {showCopyMessage && <div className="copy-message show">Code copied!</div>}
        </div>

        <div className="button-container">
          <button
            type="button"
            className="purchase"
            data-billgang-product-path="gyg"
            data-billgang-domain="supplystore.bgng.io"
          >
            Purchase
          </button>
          <button
            type="button"
            className="verify"
            onClick={handleVerify}
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Verify'}
          </button>
        </div>
      </form>
    </main>
  );
};

export default GYG;