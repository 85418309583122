import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Contact, Terms } from './popups';
import '../styles/menu.css';
import '../styles/popup.css';

const Menu = () => {
    const [isActive, setIsActive] = useState(false);
    const [popup, setPopup] = useState({
        show: false,
        content: null,
    });
    const menuContainerRef = useRef(null);
    const menuToggleRef = useRef(null);

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const handlePopupTrigger = (content, e) => {
        e.preventDefault();
        setPopup({
            show: true,
            content,
        });
    };

    const closePopup = () => {
        setPopup({
            show: false,
            content: null,
        });
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                window.innerWidth <= 768 &&
                menuContainerRef.current &&
                menuToggleRef.current &&
                !menuContainerRef.current.contains(event.target) &&
                !menuToggleRef.current.contains(event.target)
            ) {
                setIsActive(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, []);

    return (
        <>
            <div className={`menu-container ${isActive ? 'active' : ''}`} ref={menuContainerRef}>
                <nav className="menu">
                    <button
                        className={`menu-toggle ${isActive ? 'active' : ''}`}
                        onClick={handleToggle}
                        ref={menuToggleRef}
                    />
                    <div className="top">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                        </ul>
                    </div>
                    <div className="bottom">
                        <ul>
                            <li><Link to="/gyg">GYG</Link></li>
                            <li><Link to="/order">Track Order</Link></li>
                            <li>
                                <a
                                    href="#"
                                    className="popup-trigger"
                                    onClick={(e) => handlePopupTrigger(<Contact />, e)}
                                >
                                    Contact
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="popup-trigger"
                                    onClick={(e) => handlePopupTrigger(<Terms />, e)}
                                >
                                    Terms
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            {popup.show && (
                <div className="popup" onClick={(e) => {
                    if (e.target === e.currentTarget) closePopup();
                }}>
                    <div className="popup-content">
                        <button className="close-popup" onClick={closePopup}>&times;</button>
                        {popup.content}
                    </div>
                </div>
            )}
        </>
    );
};

export default Menu;
