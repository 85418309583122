import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Menu from './components/Menu';
import GYG from './components/GYG';
import Order from './components/Order';
import './styles/styles.css';

const App = () => {
  return (
    <Router>
      <div>
        <Menu />
        <Routes>
          <Route path="/" element={<Navigate to="/gyg" replace />} />
          <Route path="/gyg" element={<GYG />} />
          <Route path="/order" element={<Order />} />
          <Route path="/terms" element={<h1>Terms & Conditions</h1>} />
          <Route path="/contact" element={<h1>Contact Us</h1>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;