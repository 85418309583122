import React, { useState, useEffect, useRef } from 'react';

const LoadingDots = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => prev.length >= 3 ? '' : prev + '.');
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <span>{dots}</span>;
};

const OrderPopup = ({ orderId, status, deliveryList, quantity, onClose }) => {
  const [order, setOrder] = useState({ status: status, delivery: deliveryList });
  const [copiedIndex, setCopiedIndex] = useState(null);
  const listRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [copyAllClicked, setCopyAllClicked] = useState(false);

  const handleScroll = () => {
    if (!listRef.current) return;
    const { scrollHeight, scrollTop, clientHeight } = listRef.current;
    const buffer = 20;
    setIsAtBottom(scrollHeight - scrollTop - clientHeight < buffer);
  };

  const handleCopy = async (line, index) => {
    try {
      await navigator.clipboard.writeText(line);
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleCopyAll = async () => {
    try {
      const allCredentials = order.delivery
        .map(item => `${item.email}:${item.password}`)
        .join('\n');
      await navigator.clipboard.writeText(allCredentials);
      setCopyAllClicked(true);
      setTimeout(() => setCopyAllClicked(false), 2000);
    } catch (err) {
      console.error('Failed to copy credentials:', err);
    }
  };

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const response = await fetch(`/api/order/${orderId}`);
        const data = await response.json();
        setOrder(data);
      } catch (err) {
        console.error('Failed to fetch order status:', err);
      }
    };

    const interval = setInterval(() => {
      if (order.status !== 'COMPLETE') {
        fetchOrderStatus();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [orderId, order.status]);

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-header">
          <h1>
            {order.status === 'PENDING' || order.status === 'PROCESSING' ? (
              <>Generating Your Order ({order.delivery.length}/{quantity})<LoadingDots /></>
            ) : (
              orderId
            )}
          </h1>
          <div className="popup-actions">
            <button className="copy-all-button" onClick={handleCopyAll}>
              {copyAllClicked ? 'Copied' : '❐'}
            </button>
            <button className="close-popup" onClick={onClose}>×</button>
          </div>
        </div>
        
        <div className="popup-content" onScroll={handleScroll} ref={listRef}>
          <div className="delivery-list">
            {order.delivery.map((item, index) => (
              <button
                key={`${item.email}-${index}`}
                className={`delivery-button ${copiedIndex === index ? 'copied' : ''}`}
                onClick={() => handleCopy(`${item.email}:${item.password}`, index)}
              >
                <div className="delivery-button-content">
                  <div className="delivery-credentials">
                    {item.email}:{item.password}
                  </div>
                  <div className="delivery-expiry">
                    Expires: {new Date(item.expiredAt).toLocaleString()}
                  </div>
                </div>
                {copiedIndex === index && (
                  <div className="copy-notification">Copied!</div>
                )}
                {/* <div className="status-icon">
                  <span className={item.voucher ? 'check' : 'cross'}>
                    {item.voucher ? '✓' : '✗'}
                  </span>
                </div> */}
              </button>
            ))}
          </div>
          <div className="bottom-status">
            {order.status === 'PENDING' || order.status === 'PROCESSING' ? (
              <>Generated {order.delivery.length}/{quantity}<LoadingDots /></>
            ) : (
              'Your order is complete'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPopup;