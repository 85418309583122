const Terms = () => {
  return (
    <div className="popup-page">
      <h1>Terms of Service</h1>
      <p>You need it, we supply it. You get what you pay for. We don’t collect your information.</p>
    </div>
  );
};

export default Terms;
