import React from 'react';

const Contact = () => {
  return (
    <div className="popup-page">
      <h1>Contact Us</h1>

      <p>If you have any questions, open a ticket in our Discord server.</p>

      <div>
        <h3>Discord Community</h3>
        <a
          href="https://discord.gg/KP266kV3"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          Join Discord Server
        </a>

        {/* Add spacing here */}
        <div style={{ margin: '20px 0' }}></div>

        <h3>Telegram Channel</h3>
        <a
          href="https://t.me/+48KI7hlxx2RkMWY1"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          Join Telegram Channel
        </a>
        
        <div style={{ margin: '20px 0' }}></div>

        <p>For the fastest response, please join our Discord community where our support team and community members can assist you.</p>
      </div>
    </div>
  );
};

export default Contact;
