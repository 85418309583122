import React, { useState, useRef, useEffect } from 'react';
import OrderPopup from './popups/OrderPopup';
import '../styles/order.css';
import '../styles/logo.css';
import '../styles/popup.css';

const Order = () => {
  const [email, setEmail] = useState('');
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [deliveryList, setDeliveryList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const emailInputRef = useRef(null);
  const orderIdInputRef = useRef(null);

  const CONFIG = {
    emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    uuidRegex: /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    API_URL: 'https://api.supplystore.store/track-order',
  };

  const validateEmail = () => {
    const isValid = CONFIG.emailRegex.test(email.trim());
    if (!isValid) {
      setError('Please enter a valid e-mail address');
      return false;
    }
    return true;
  };

  const validateOrderId = () => {
    const isValid = CONFIG.uuidRegex.test(orderId.trim());
    if (!isValid) {
      setError('Please enter a valid Order ID');
      return false;
    }
    return true;
  };

  const handleTrack = async (e) => {
    e.preventDefault();
    
    if (!orderId.trim() || !email.trim()) {
      setError('Both Order ID and Email are required.');
      return;
    }

    if (!validateEmail()) return;
    if (!validateOrderId()) return;

    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(CONFIG.API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderId: orderId.trim(), email: email.trim() }),
      });
      
      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        // Show popup regardless of delivery array status
        setStatus(data.status);
        setQuantity(data.quantity);
        setDeliveryList(data.delivery || []); // Handle null/undefined delivery
        setShowPopup(true);
      }
    } catch (err) {
      setError('Network error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyLine = async (line) => {
    try {
      await navigator.clipboard.writeText(line);
      alert('Copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  useEffect(() => {
    const validateInputs = () => {
      if (email && !CONFIG.emailRegex.test(email.trim())) {
        setError('Please enter a valid e-mail address');
      } else if (orderId && !CONFIG.uuidRegex.test(orderId.trim())) {
        setError('Please enter a valid Order ID');
      } else {
        setError('');
      }
    };

    validateInputs();
  }, [email, orderId]);

  return (
    <main className="main-content">
      <div className="logo-container">
        <div className="logo">
          <img src="gyg.png" alt="Logo" className="bouncing-logo" />
        </div>
      </div>

      <form className="form-container" id="orderForm" onSubmit={handleTrack}>
        <div className={`form-group loading-container ${loading ? 'loading' : ''}`}>
          <div className="inputs-wrapper">
            <input
              ref={orderIdInputRef}
              type="text"
              className={`input-field ${error ? 'error' : ''}`}
              placeholder="Order ID *"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              disabled={loading}
              required
            />
            <input
              ref={emailInputRef}
              type="email"
              className={`input-field ${error ? 'error' : ''}`}
              placeholder="Email *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              required
            />
          </div>
          <div className="loading-spinner" />
          {error && <div className="error-message show">{error}</div>}
        </div>
        
        <button
          type="submit"
          className="verify"
          disabled={loading}
        >
          {loading ? 'Tracking...' : 'TRACK'}
        </button>
      </form>

      {showPopup && (
        <OrderPopup
          orderId={orderId}
          status={status}
          quantity={quantity}
          deliveryList={deliveryList}
          onClose={() => setShowPopup(false)}
          onCopyLine={handleCopyLine}
        />
      )}
    </main>
  );
};

export default Order;